import { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { getUserInfo } from "./fechclient";
// import Spinner from "react-spinner-material";
import logo from "./logo/mpu-logo.png";
import { firebase, db, storage } from "./firebase";
import LoginPage from "./components/login";

// const LoginPage = () => {
//   const [errorAuth, setErrorAuth] = useState(null);
//   const handleLogin = async (e) => {
//     const provider = new firebase.auth.GoogleAuthProvider();
//     try {
//       await firebase.auth().signInWithPopup(provider);
//     } catch (error) {
//       setErrorAuth(error);
//     }
//   };
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         flexDirection: "column",
//       }}
//     >
//       <Logo />
//       <div>
//         <h3 style={{ textAlign: "center" }}>كلية مزايا الجامعة</h3>
//       </div>
//       <div style={{ margin: "auto" }}>
//         <button
//           className="btn btn-primary btn-round"
//           style={{ fontSize: "14px" }}
//           onClick={handleLogin}
//         >
//           اضغط هنا من اجل الدخول
//         </button>
//         {errorAuth ? (
//           <div>
//             <p>
//               <i>{errorAuth.message}</i>
//             </p>
//             <h4>Please try again</h4>
//           </div>
//         ) : null}
//       </div>
//     </div>
//   );
// };
// user card section start
const SeccussMessage = ({ student, user, url }) => {
  useEffect(() => {
    let didIt = true;
    if (didIt) {
      db.collection("users").doc(user.uid).set(
        {
          email: student.email,
          password: student.password,
          code: true,
        },
        { merge: true }
      );
    }
  }, [user, student]);
  return (
    <div>
      <div>
        <div className="card-body">
          <p className="card-text">
            البريد الالكتروني
            <br />
            {student.email}
          </p>
          <p className="card-text">
            كلمة المرور
            <br />
            {student.password}
          </p>
          <a
            className="btn btn-primary btn-round"
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            <i className="now-ui-icons arrows-1_cloud-download-93"></i> تنزيل
            دليل الطالب الالكتروني
          </a>
        </div>
      </div>
    </div>
  );
};
const UserCard = ({ info, user, setInfo }) => {
  const [alert, setAlert] = useState(true);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    if (alert) {
      const ref = storage.refFromURL("gs://validation-maz/guide.pdf");
      ref.getDownloadURL().then((u) => setUrl(u));
    }
    return () => setAlert(false);
  }, [alert]);
  const handlerAlert = () => {
    setAlert(false);
    setInfo(null);
  };
  const { verify } = info;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "auto",
        width: "50%",
      }}
    >
      {verify === "موثق" ? (
        <div>
          {alert ? (
            <SweetAlert
              title="معلومات الطالب او الطالبة
            "
              onConfirm={handlerAlert}
              confirmBtnStyle={{ textDecoration: "none" }}
              success
            >
              <SeccussMessage student={info} user={user} url={url} />
            </SweetAlert>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div>
          {alert ? (
            <SweetAlert
              warning
              onConfirm={handlerAlert}
              title="توضيح"
              confirmBtnStyle={{ textDecoration: "none" }}
            >
              <h3 className="text-muted">
                المعلومات المدخلة صحيحة ولديك حساب في الكلية . لم يظهر حسابك
                لاحد الاسباب
              </h3>
              <div className="text-center">
                <p className="text-center">انك لم تقوم بدفع قسط الكلية</p>
                <small className="text-muted text-center" style={{ fontSize: "20px" }}>
                  او
                </small>
                <p className="text-center">معلوماتك لم تحدث حاليا يمكنك المحاولة قي وقت اخر</p>
              </div>
            </SweetAlert>
          ) : null}
        </div>
      )}
    </div>
  );
};
// user card section end
const Logo = () => {
  return <img src={logo} alt="logo" className="logo" />;
};
// const Loading = () => {
//   return (
//     <div style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
//       <Spinner
//         size={120}
//         spinnercolor={"#333"}
//         spinnerwidth={2}
//         visible={true}
//       />
//     </div>
//   );
// };
// form section start here
const WarnMessage = ({ info, setInfo}) => {
  const [alert, setAlert] = useState(true);
  const handlerAlert = () => {
    setAlert(false);
    setInfo(null)
  };
  return (
    <div>
      {alert ? (
        <SweetAlert
          error
          title="خطاء"
          onConfirm={handlerAlert}
          confirmBtnStyle={{ textDecoration: "none" }}
        >
          {info.message}
        </SweetAlert>
      ) : null}
    </div>
  );
};
const MessageUser = ({ info, user, setInfo }) => {
  if (info !== null) {
    if (info.err === -1) return <WarnMessage info={info} setInfo={setInfo}/>;
    if (info.err === -2) return <WarnMessage info={info} setInfo={setInfo}/>;
  }
  return <UserCard info={info} user={user} setInfo={setInfo}/>;
};
const InputField = () => {
  return (
    <div className="form-group">
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        className="form-control"
        id="exampleInputNumber"
        style={{ width: "50%", display: "block", margin: "auto" }}
      />
    </div>
  );
};
const Button = ({ loading }) => {
  const padding = loading ? "15px" : "0px";
  return (
    <button
      className="btn btn-primary btn-round btn-lg"
      style={{ margin: "auto", fontSize: "14px" }}
      disabled={loading ? true: false}
    >
      {loading ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      ) : null}
      <span style={{ paddingRight: padding, fontSize: "20px" }}>ارسل</span>
    </button>
  );
};
const UserForm = ({ setLoading, setStdInfo, loading }) => {
  const handleStdInfo = (number) => {
    setLoading(true);
    getUserInfo(number).then((d) => {
      setStdInfo(d);
      setLoading(false);
    });
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const value = e.target.elements[0].value;
        handleStdInfo(value);
        e.target.reset();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "5%",
      }}
    >
      <InputField />
      <Button loading={loading} />
    </form>
  );
};
const Input = ({ user }) => {
  const [stdInfo, setStdInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <div style={{ marginTop: "3%" }}>
      <UserForm
        setLoading={setLoading}
        setStdInfo={setStdInfo}
        loading={loading}
      />

      {stdInfo !== null ? <MessageUser info={stdInfo} user={user} setInfo={setStdInfo}/> : null}
    </div>
  );
};
// section form end here
const Nav = () => {
  const sigOut = (e) => {
    firebase.auth().signOut();
  };
  return (
    <nav style={{ width: "15%", textAlign: "center" }}>
      <button className="btn btn-primary btn-lg" onClick={sigOut}>
        خروج
      </button>
    </nav>
  );
};

const UserDashBoard = ({ user }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Nav />
      <Logo />
      <div>
        <h1 className="title">منصة الطالب او الطالبة لتاكيد المعلومات</h1>
        <h2 className="subtitle">
          {" "}
          يرجي كتابة الرقم الامتحاني او الرقم السري بصور صحيحة ومن ثم اضغط على
          ارسل
        </h2>
        <h3 style={{ margin: "auto", textAlign: "center" }}>
          <i className="now-ui-icons users_single-02"></i> مرحبا{" "}
          {user.displayName}
        </h3>
        {user.code ? (
          <UserCard user={user} info={user} />
        ) : (
          <Input user={user} />
        )}
      </div>
    </div>
  );
};
function App() {
  const user = useAuth();
  return (
    <div>{user !== null ? <UserDashBoard user={user} /> : <LoginPage />}</div>
  );
}

const useAuth = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((userFirebase) => {
      if (userFirebase) {
        const user = {
          displayName: userFirebase.displayName,
          photoURL: userFirebase.photoURL,
          uid: userFirebase.uid,
        };
        setUser(user);
        db.collection("users").doc(userFirebase.uid).set(user, { merge: true });
      } else {
        setUser(null);
      }
    });
  }, []);
  return user;
};
export default App;
