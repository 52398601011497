import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'
import "firebase/storage"


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
    apiKey: "AIzaSyBhCdqUFKulSvdOpT4RciOuUlUlgdzYFwA",
    authDomain: "validation-maz.firebaseapp.com",
    projectId: "validation-maz",
    storageBucket: "validation-maz.appspot.com",
    messagingSenderId: "309834144450",
    appId: "1:309834144450:web:e15289387ef0dd5585f95b",
    measurementId: "G-0SZV2K0MQN"
  };

if(!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.firestore();
const storage = firebase.storage();

export {
    db,
    firebase,
    storage
}