import { useState } from "react";
import { firebase } from "../firebase";
import Logo from "./logo";
const Button = ({handler}) => {
  return (
    <div>
      <button className="btn btn-google mb-2" onClick={handler}>
        <i className="fab fa-google-plus-g"></i> الدخول استخدام حساب الكوكل
      </button>
    </div>
  );
};
const LoginPage = () => {
  const [errorAuth, setErrorAuth] = useState(null);
  const handleLogin = async (e) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      setErrorAuth(error);
    }
  };
  return (
    <div className="container" style={{ marginTop: "10%" }}>
      <div className="row m-auto" style={{ position: "relative" }}>
        <div
          style={{
            height: "400px",
            width: "100%",
            transform: "rotate(-0.5deg)",
            top: "50px",
          }}
          className="btn-primary card"
        ></div>
        <div
          className="card card-testimonial"
          style={{ position: "absolute", zIndex: "999" }}
        >
          <div className="card-header">
            <Logo />
          </div>
          <div className="card-body">
            <h4 className="card-title">كلية مزايا الجامعة</h4>
            <p className="cart-text">
              المنصة الخاصة تاكيد حسابات الطلبة الجدد - المرحلة الاولى
            </p>
            <Button handler={handleLogin}/>
          </div>
          {errorAuth ? (<div className="alert btn-primary m-auto" role="alert" style={{width: "50%"}}>
              {errorAuth.message}
          </div>): null}
          <div className="card-footer">
            <small>&copy; جميع الحقوق محفوظة لكية مزايا الجامعة</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
