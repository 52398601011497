const fetch = require("node-fetch")

const URL = "https://us-central1-validation-maz.cloudfunctions.net/api"
// const URL = "http://localhost:5001/validation-maz/us-central1/api"
const getUserInfo = async (examNum) => {
    const data = {number: examNum}
    const resp = await fetch(URL, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }

    })
    return await resp.json()
}

export {
    getUserInfo
}