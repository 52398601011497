import logo from "../logo/mpu-logo.png";
const Logo = () => {
  return (
    <img
      src={logo}
      alt="logo"
      className="img img-raised"
      style={{ boxShadow: "none", position: "relative", top: "-100px" }}
    />
  );
};

export default Logo;
